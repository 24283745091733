const PRODUCTION = "PRODUCTION"
const TESTING = "TESTING"

export const Environment = PRODUCTION
//TESTING

export const AppVersion = require("../../package.json").version

export const isProdEnv = () => Environment === PRODUCTION

export const ENABLE_BG_SYNC = false // TODO - REMOVE ME!!!
export const ENABLE_BG_STORE_FAILED_REQS = false // TODO - REMOVE ME!!!
export const ENABLE_RETRIES = true
export const ENABLE_CAMERA = true
